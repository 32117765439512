import React, { memo, useEffect } from "react";
import Div from "@jumbo/shared/Div";
import { useNavigate, useParams } from "react-router-dom";
import ChipStatus from "app/components/ChipStatus";
import { colors } from "app/utils/Colors";
import {
  useFetchTicketShowQuery,
  useLazyFetchTicketShowQuery,
} from "../features/Tickets/tickets-slice";
import { Skeleton } from "@mui/material";

function TicketHeader() {
  const navigate = useNavigate();
  const handleClickCompany = (id) => {
    navigate(`/ticket/empresa/${id}/todos`);
  };

  const handleClickUser = (id) => {
    navigate(`/ticket/cliente/${id}/todos`);
  };
  const { id } = useParams();

  const { data: ticket, isLoading, isFetching } = useFetchTicketShowQuery(id);

  if (isLoading || isFetching)
    return <Skeleton variant="rectangular" width="100%" height={69} />;

  return (
    <Div style={$headerBlock}>
      {ticket?.id && (
        <>
          <Div
            onClick={() =>
              handleClickCompany(
                ticket?.responsible_user?.profile?.unit?.company.id
              )
            }
            style={{ ...$tagsTop, ...$tagsTopLeft }}
          >
            {ticket.responsible_user?.profile?.unit?.company?.name}
          </Div>
          <Div
            onClick={() => handleClickUser(ticket.responsible_user?.id)}
            style={$tagsTop}
          >
            {ticket.responsible_user?.profile?.name}
          </Div>
        </>
      )}
      <Div style={{ ...$tagsTop, ...$tagsTopRight }}>
        {ticket?.id ? (
          <>
            <ChipStatus status={ticket.status?.id} />{" "}
            <span style={{ padding: 6 }}>Ticket #{ticket.id}</span>
          </>
        ) : (
          <span style={{ padding: 6 }}>Criação de Ticket</span>
        )}
      </Div>
    </Div>
  );
}
const $tagsTop = {
  border: "solid 1px ",
  borderColor: colors.borderColors,
  padding: "6px 12px",
  backgroundColor: colors.standardGray,
  display: "flex",
  alignContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",
};
const $tagsTopLeft = {
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  borderRight: 0,
};
const $tagsTopRight = {
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  borderLeft: 0,
  backgroundColor: "#fff",
};
const $headerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};

export default memo(TicketHeader);
