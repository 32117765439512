import {
  asyncTicketsActions,
  asyncTicketsResponsesActions,
} from "app/redux/reducers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Skeleton, Stack } from "@mui/material";
import Div from "@jumbo/shared/Div";
import "./style.css";
import { colors } from "app/utils/Colors";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SplitButton from "app/components/SplitButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { normalizeDate, renderAvatar, firstLetter } from "app/utils/appHelpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import SwitchTickets from "app/components/SwitchTickets";

import TicketHeader from "./components/TicketHeader";
import TicketResponse from "./components/TicketResponse";
import EditorField from "./components/fields/EditorField";
import AttendantField from "./components/fields/AttendantField";
import CategoryField from "./components/fields/CategoryField";
import CompanyField from "./components/fields/CompanyField";
import UnitField from "./components/fields/UnitField";
import CustomerField from "./components/fields/CustomerField";
import FamilyField from "./components/fields/FamilyField";
import {
  useEditTicketMutation,
  useFetchTicketShowQuery,
  useSubmitResponseMutation,
} from "./features/Tickets/tickets-slice";

const initialValues = {
  title: "",
  description: "",
  category_id: undefined,
  status: "",
  attendant_id: undefined,
  client_id: undefined,
  company_id: undefined,
  unit_id: undefined,
  family_id: undefined,
};
const TicketDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [switchState, setSwitchState] = React.useState(true);

  const auth = useSelector((state) => state.auth);

  const { data: ticket, isLoading, isFetching } = useFetchTicketShowQuery(id);
  const [editTicket] = useEditTicketMutation();
  const [submitResponse, { isLoading: isLoadingResp }] =
    useSubmitResponseMutation();

  const formik = useFormik({
    initialValues:
      (ticket && {
        description: undefined,
        category_id: ticket?.category?.id,
        status: 2,
        attendant_id: ticket?.attendant?.id,
        client_id: ticket?.responsible_user?.id,
        company_id: ticket?.responsible_user?.profile?.unit?.company?.id,
        unit_id: ticket?.responsible_user?.profile.unit_id,
        family_id: ticket?.product_family_id,
      }) ||
      initialValues,
    onSubmit: async (values) => {
      handleEdit(values);
    },
    enableReinitialize: true,
  });

  React.useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  const throgleSwitch = (value) => {
    setSwitchState(value);
  };

  const createNewSo = () => {
    navigate("/os/novo", { state: { ticket: ticket } });
  };

  const handleEdit = async (values) => {
    if (
      formik.values.description !== undefined &&
      formik.values.description !== "<p></p>\n"
    ) {
      await handleSubmitAnswer();
      console.log(`>>>>>>asdasdas>>>>>>>>>>`, formik.values.description);
    }
    console.log(`>>>>>>>>>>>>>>>>`, formik.values.description);

    const data = {
      ticket_category_id: values.category_id,
      ticket_status_id: values.status,
      user_id: values.attendant_id,
      unit_id: values.unit_id,
      responsible_user_id: values.client_id,
      product_family_id: values.family_id,
    };

    await editTicket({ id: id, body: data });
    navigate("/tickets/aberto");
  };

  const handleSubmitAnswer = async () => {
    const dataAnswer = {
      response: formik.values.description,
      ticket_id: id,
      is_public: switchState,
    };

    await submitResponse(dataAnswer);
  };

  // if (isFetching || isLoading) {
  //   return (
  //     <div className="grid grid-cols-[1fr_2fr] gap-1">
  //       <Skeleton
  //         className="rounded-md"
  //         variant="rectangular"
  //         width="100%"
  //         height={800}
  //       />
  //       <Skeleton
  //         className="rounded-md"
  //         variant="rectangular"
  //         width="100%"
  //         height={800}
  //       />
  //     </div>
  //   );
  // }

  return (
    <>
      <TicketHeader />
      <form style={$form} onSubmit={formik.handleSubmit}>
        <Div style={$container}>
          <Stack style={$leftBlock}>
            <Div className="py-10 px-5 border-solid border-0 border-b-[1px] border-gray-300 flex gap-5 flex-col">
              <span>Informações do atendimento</span>
              <AttendantField formikRef={formik} />
              <CategoryField formikRef={formik} />
              <span>Dados do cliente</span>
              <CompanyField formikRef={formik} />
              <UnitField formikRef={formik} />
              <CustomerField formikRef={formik} />
              <span>Dados complementares</span>
              <FamilyField formikRef={formik} />
            </Div>
          </Stack>
          <Stack className={"midBlock"} style={$midBlock}>
            {ticket?.title && (
              <Div style={$titleTicketBlock}>
                {ticket?.responsible_user?.profile?.avatar ? (
                  <Div>
                    {renderAvatar(ticket?.responsible_user?.profile?.avatar)}
                  </Div>
                ) : (
                  <Div>
                    {firstLetter(ticket?.responsible_user?.profile?.name)}
                  </Div>
                )}

                <Div>
                  <Div style={$ticektTitle}>{ticket.title}</Div>

                  <Div>
                    {normalizeDate(ticket.created_at)} -{" "}
                    {ticket.responsible_user?.profile?.name} -{" "}
                    {ticket.responsible_user?.email}
                  </Div>
                </Div>
              </Div>
            )}
            <Div
              style={
                switchState
                  ? {
                      ...$ticketResponseFieldBlockWrapper,
                      ...$switchOn,
                    }
                  : {
                      ...$ticketResponseFieldBlockWrapper,
                      ...$switchOff,
                    }
              }
            >
              <Div style={$ticketResponseFieldBlock}>
                {auth.user.profile.avatar ? (
                  <Div>{renderAvatar(auth.user.profile.avatar)}</Div>
                ) : (
                  <Div>{firstLetter(auth.user.profile.name)}</Div>
                )}

                <EditorField formikRef={formik} />
              </Div>
              <Div className="pl-[73px]">
                <SwitchTickets
                  switchState={switchState}
                  setSwitchState={throgleSwitch}
                />
              </Div>
            </Div>
            <TicketResponse />
          </Stack>
        </Div>
        <Div
          style={{
            ...$footerBlock,
            justifyContent: ticket?.id ? "space-between" : "flex-end",
          }}
        >
          {ticket?.id && (
            <Button
              variant="outlined"
              data-cy="openModal"
              sx={{
                justifyContent: "center",
                color: "black",
                backgroundColor: "white",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
              onClick={() => createNewSo()}
            >
              Nova Ordem de Serviço
            </Button>
          )}
          <SplitButton
            loading={isLoadingResp}
            setFieldValue={formik.setFieldValue}
          />
        </Div>
      </form>
    </>
  );
};
const $form = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const $ticketResponseFieldBlock = {
  padding: 20,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  transition: "background-color 0.4s ease",
};
const $ticketResponseFieldBlockWrapper = {
  transition: "background-color 0.4s ease",
};

const $switchOn = {
  backgroundColor: "#fff",
};

const $switchOff = {
  backgroundColor: "#fff6f6",
};

const $titleTicketBlock = {
  display: "flex",
  flexDirection: "row",
  padding: 20,
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
};
const $ticektTitle = {
  fontSize: 20,
  fontWeight: 500,
};

const $footerBlock = {
  backgroundColor: colors.standardWhite,
  maxWidth: "100%",
  padding: 10,
  flexDirection: "row",
  display: "flex",
  borderTop: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: colors.borderColors,
  minHeight: "6vh",
  flexWrap: "wrap",
  alignContent: "center",
};

const $midBlock = {
  backgroundColor: "#fff",
  flex: 1,
  overflow: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "red",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  maxHeight: "77vh",
};

const $leftBlock = {
  backgroundColor: colors.standardGray,
  flex: 1,
  maxWidth: "25%",
};

const $container = {
  flex: 1,
  display: "flex",
  padding: 0,
};

export default TicketDetails;
