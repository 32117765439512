import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TICKET_CONSTANTS } from "./ticket-constants";

export const ticketsSlice = createApi({
  reducerPath: "ticketsSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchAllTicketsIndex: builder.query({
        query(id) {
          return `tickets?orderBy=id,desc`;
        },
        providesTags: [TICKET_CONSTANTS.INDEX],
      }),
      fetchUserShow: builder.query({
        query(id) {
          return `users/${id}`;
        },
        providesTags: [TICKET_CONSTANTS.USER],
      }),
      fetchTicketShow: builder.query({
        query(id) {
          return `tickets/${id}`;
        },
        providesTags: [TICKET_CONSTANTS.SHOW],
      }),
      fetchResponses: builder.query({
        query({ id, page }) {
          return `tickets/responses?ticket_id=${id}&orderBy=id,desc&page=${page}`;
        },
        providesTags: [TICKET_CONSTANTS.RESPONSE],
      }),
      submitResponse: builder.mutation({
        query(body) {
          return {
            url: "tickets/responses",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      newTicket: builder.mutation({
        query(body) {
          return {
            url: "tickets",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      editTicket: builder.mutation({
        query({ id, body }) {
          return {
            url: `tickets/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
      editUser: builder.mutation({
        query({ id, body }) {
          return {
            url: `users/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: [
          TICKET_CONSTANTS.USER,
          TICKET_CONSTANTS.RESPONSE,
          TICKET_CONSTANTS.SHOW,
          TICKET_CONSTANTS.INDEX,
        ],
      }),
    };
  },
});

export const {
  useEditUserMutation,
  useFetchUserShowQuery,
  useNewTicketMutation,
  useEditTicketMutation,
  useSubmitResponseMutation,
  useLazyFetchResponsesQuery,
  useFetchResponsesQuery,
  useLazyFetchTicketShowQuery,
  useFetchTicketShowQuery,
} = ticketsSlice;
