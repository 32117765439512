import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FILEMANAGER_SLICE } from "../helpers/constants";

export const fileManagerSlice = createApi({
  reducerPath: "fileManagerSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      listCategoryInput: builder.query({
        query: () => "file-manager-categories",
        providesTags: [FILEMANAGER_SLICE.LIST_CATEGORIES],
      }),
      listFiles: builder.query({
        query({ values }) {
          let queryParam = [];
          if (values.page) {
            queryParam.push(`page=${values.page}`);
          }
          if (values.search) {
            queryParam.push(`search=${values.search}`);
          }
          if (values.category_id) {
            queryParam.push(`file_manager_category_id=${values.category_id}`);
          }
          return `file-manager/?${
            queryParam.length > 0 && queryParam.join("&")
          }`;
        },
        providesTags: [FILEMANAGER_SLICE.LIST_FILES],
      }),
      listFilesCategories: builder.query({
        query(values) {
          console.log(`ccaegoriews values`, values);
          let queryParam = [];
          if (values.category_id) {
            queryParam.push(`parent_category_id=${values.category_id}`);
          }
          if (values.hasParent) {
            queryParam.push(
              `hasParent=${values.hasParent === 1 ? "true" : "false"}`
            );
          }
          console.log(`queryParam`, queryParam);
          return `file-manager-categories?${queryParam.join("&")}`;
        },
        providesTags: [FILEMANAGER_SLICE.LIST_FILES_CATEGORIES],
      }),
      uploadFiles: builder.mutation({
        query({ files, file_manager_category_id }) {
          const formData = new FormData();
          while (files.length) {
            formData.append("name", files[0].name);
            formData.append("file", files.shift());
          }
          formData.append("file_manager_category_id", file_manager_category_id);
          return {
            url: `/file-manager`,
            method: "POST",
            body: formData,
            formData: true,
          };
        },
        invalidatesTags: [
          FILEMANAGER_SLICE.LIST_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES,
        ],
      }),

      createCategory: builder.mutation({
        query({ name, parent_category_id }) {
          let body = {
            name: name,
          };
          if (parent_category_id) {
            body.parent_category_id = parent_category_id;
          }
          return {
            url: `/file-manager-categories`,
            method: "POST",
            body: body,
          };
        },
        invalidatesTags: [
          FILEMANAGER_SLICE.LIST_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES,
        ],
      }),

      deleteFile: builder.mutation({
        query(values) {
          return {
            url: `/file-manager/${values.id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: [
          FILEMANAGER_SLICE.LIST_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES,
        ],
      }),
      deleteCategory: builder.mutation({
        query(values) {
          return {
            url: `/file-manager-categories/${values.id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: [
          FILEMANAGER_SLICE.LIST_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES,
        ],
      }),
      renameCategory: builder.mutation({
        query(values) {
          return {
            url: `/file-manager-categories/${values.id}`,
            method: "PUT",
            body: { name: values.name },
          };
        },
        invalidatesTags: [
          FILEMANAGER_SLICE.LIST_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES_CATEGORIES,
          FILEMANAGER_SLICE.LIST_FILES,
        ],
      }),
    };
  },
});

export const {
  useRenameCategoryMutation,
  useDeleteCategoryMutation,
  useDeleteFileMutation,
  useListCategoryInputQuery,
  useCreateCategoryMutation,
  useUploadFilesMutation,
  useListFilesCategoriesQuery,
  useListFilesQuery,
} = fileManagerSlice;
