import Div from "@jumbo/shared/Div";
import { Button, FormControl, Typography } from "@mui/material";
import React from "react";
import JumboSearch from "@jumbo/components/JumboSearch";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PostAddIcon from "@mui/icons-material/PostAdd";
import useFileManagerContext from "../hooks/useFileManagerContext";
import SearchInput from "./inputs/SearchInput";
function FileManagerHeader() {
  const { setOpenModal, setOpenModalCategory } = useFileManagerContext();

  return (
    <div className="flex flex-row items-center justify-between py-4">
      <Typography variant={"h2"}>Gerenciamento de Arquivos</Typography>
      <div className="flex gap-3">
        <Button
          alt="Criar Categoria"
          color="error"
          variant="contained"
          onClick={() => setOpenModalCategory(true)}
        >
          <CreateNewFolderIcon
            sx={{
              color: "#FFF",
            }}
          />
        </Button>
        <Button
          className=""
          variant="contained"
          color="success"
          onClick={() => setOpenModal(true)}
        >
          <PostAddIcon
            sx={{
              color: "#FFF",
            }}
          />
        </Button>
        <SearchInput />
      </div>
    </div>
  );
}

export default FileManagerHeader;
